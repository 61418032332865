import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Paper } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { useTrainingPlans } from 'hooks';
import { Subheader, TabPanel } from 'components';
import TrainingPlanDetails from 'features/trainingPlan/TrainingPlanDetails';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'training-plans',
		title: 'Training Plan'
	},
	{
		url: 'lessons',
		title: 'Lesson'
	},
	{
		url: 'submissions',
		title: 'Submissions'
	}
];

const TrainingPlanShow = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [lessons, setLessons] = useState([]);
	const [tabValue, setTabValue] = useState(0);
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);

	const { trainingPlan, findTrainingPlan, isLoading } = useTrainingPlans({
		id: id
	});

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const handleEdit = () => {};

	const createButton = useMemo(() => {
		return createConfig.url !== 'submissions' ? (
			<Button
				variant="contained"
				startIcon={'Edit'}
				onClick={handleEdit}
				color="primary"
			>
				{createConfig.title}
			</Button>
		) : null;
	}, [createConfig.url]);

	useEffect(() => {
		if (id) {
			findTrainingPlan(id);
		}
	}, [id]);

	useEffect(() => {
		if (trainingPlan?.lessons) {
			setLessons(trainingPlan.lessons);
		}
	}, [trainingPlan]);

	return (
		<div>
			<Subheader
				title={isLoading ? 'Loading...' : trainingPlan.name}
				buttons={[createButton]}
			/>
			<Container maxWidth="lg">
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="Training Plan"
				>
					<Tab label="Training Plan" />
					<Tab label="Lessons" />
					<Tab label="Submissions" />
				</Tabs>
				<Paper>
					<TabPanel value={tabValue} index={0}>
						{trainingPlan && (
							<TrainingPlanDetails trainingPlan={trainingPlan} />
						)}
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<div>lessons</div>
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						<div>submissions</div>
					</TabPanel>
				</Paper>
			</Container>
		</div>
	);
};

export default TrainingPlanShow;
