import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, SubheaderV2 } from 'components';
import { useSkillCategories, useTrainingPlanDrills } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TrainingPlanForm from 'features/trainingPlan/TrainingPlanForm';
import { trainingPlanValidation } from 'validations/trainingPlan';

const TrainingPlanDrillEdit = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const {
		findTrainingPlanDrill,
		trainingPlanDrill,
		handleChange,
		updateTrainingPlanDrill
	} = useTrainingPlanDrills({ id: id });
	const { categories, findCategories } = useSkillCategories();

	const handleSaveClick = async () => {
		const valid = trainingPlanValidation(trainingPlanDrill);

		if (!valid.isValid) {
			showAlertError(valid.messages[0]);
			return;
		}

		const response = await updateTrainingPlanDrill(trainingPlanDrill);

		if (response.status && response.status !== 200) {
			const { message } = response.data;
			showAlertError(message);
			return;
		}

		navigate('/training-plans');
	};

	useEffect(() => {
		if (id) findTrainingPlanDrill(id);
	}, [id]);

	useEffect(() => {
		findCategories();
	}, []);

	console.log({ trainingPlanDrill });

	return (
		<>
			<SubheaderV2
				title="Edit Training Plan Drill"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Update Training Plan Drill
					</Button>
				]}
			/>

			<Container>
				<Box p={4}>
					<TrainingPlanForm
						trainingPlan={trainingPlanDrill}
						handleChange={handleChange}
						type={'Training Plan Drill'}
						categories={categories}
					/>
				</Box>

				<Box px={4}>
					<Grid container spacing={1}>
						<Grid item md={6} xs={12}>
							<Typography variant="body2" color="textSecondary">
								{'Training Plan Drill Image'}
							</Typography>
							<ImageUploader
								label="Upload Image"
								imageUrl=""
								handleUpload={() => {}}
								handleDelete={() => {}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</>
	);
};

export default TrainingPlanDrillEdit;
