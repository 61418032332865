import React, { useEffect } from 'react';

import {
	Box,
	Button,
	Container,
	List,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@material-ui/core';

import { useAlerts, useNavigation, useTrainingPlanDrills } from 'hooks';
import TrainingPlanDrillSortableList from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillSortableList';
import TrainingPlanDrillItem from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillItem';

const TrainingPlanDrillList = ({ handleAddClick }) => {
	const {
		trainingPlanDrills,
		findTrainingPlanDrills,
		destroyTrainingPlanDrill
	} = useTrainingPlanDrills({
		id: null
	});

	const { showAlertSuccess } = useAlerts();

	const { handleClick, handleEditClick } = useNavigation({
		history,
		url: '/training-plan-drills'
	});

	const handleDeleteClick = async (drill) => {
		await destroyTrainingPlanDrill(drill);
		showAlertSuccess('Training plan drill has been deleted');
		findTrainingPlanDrills();
	};

	const onDragEnd = (result) => {};

	useEffect(() => {
		findTrainingPlanDrills();
	}, []);

	return (
		<>
			{trainingPlanDrills.length > 0 ? (
				<TrainingPlanDrillSortableList
					id="sort-training-plan-drills"
					list={trainingPlanDrills}
					ListContainer={List}
					onDragEnd={onDragEnd}
					ListItem={({ item }) => (
						<TrainingPlanDrillItem
							key={item.id}
							drill={item}
							handleClick={handleClick}
							handleEditClick={handleEditClick}
							handleDeleteClick={handleDeleteClick}
						/>
					)}
				/>
			) : (
				<Container maxWidth="lg">
					<Box
						p={2}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						height="100%"
						width="100%"
						sx={{ minHeight: 600 }}
					>
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							{'No training plan drills'}
						</Typography>
						<Button
							onClick={handleAddClick}
							color="primary"
							variant="contained"
						>
							Add a training plan drill
						</Button>
					</Box>
				</Container>
			)}
		</>
	);
};

export default TrainingPlanDrillList;
