import React from 'react';

import { useResource } from 'hooks';

const useTrainingPlanDrills = ({ id, ...props }) => {
	const {
		id: trainingPlanDrillId,
		isLoading,
		resource: trainingPlanDrill,
		resources: trainingPlanDrills,
		errors: trainingPlanDrillErrors,
		findOne: findTrainingPlanDrill,
		findMany: findTrainingPlanDrills,
		create: createTrainingPlanDrill,
		update: updateTrainingPlanDrill,
		destroy: destroyTrainingPlanDrill,
		...rest
	} = useResource({
		id: id,
		url: '/api/v1/admin/training_plan_drills',
		name: 'training_plan_drill',
		label: 'Training Plan Drill'
	});

	return {
		trainingPlanDrillId,
		trainingPlanDrill,
		trainingPlanDrills,
		trainingPlanDrillErrors,
		isLoading,
		findTrainingPlanDrill,
		findTrainingPlanDrills,
		createTrainingPlanDrill,
		updateTrainingPlanDrill,
		destroyTrainingPlanDrill,
		...rest
	};
};

export default useTrainingPlanDrills;
