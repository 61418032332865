import React from 'react';

import {
	Button,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Typography,
	makeStyles
} from '@material-ui/core';
import { DragIndicator, MoreHoriz } from '@material-ui/icons';
import fp from 'lodash/fp';

import { useMenu } from 'hooks';

const TrainingPlanDrillItem = ({
	drill,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const classes = useStyles();

	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleClick(drill, e)}>Show</MenuItem>
			<MenuItem onClick={(e) => handleEditClick(drill, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(drill, e)}>Delete</MenuItem>
		</Menu>
	);

	const GridItem = ({ name, drill, handleClick, ...rest }) => {
		return (
			<Grid item md={2} xs={12} className={classes.item} {...rest}>
				<Button onClick={(e) => handleClick(drill, e)} className={classes.item}>
					<Typography variant="body1" className={classes.text}>
						{name}
					</Typography>
				</Button>
			</Grid>
		);
	};

	return (
		<Grid container spacing={1} className={classes.root}>
			<Grid item md={1} xs={12} className={classes.item}>
				<DragIndicator className={classes.icon} />
			</Grid>
			<GridItem
				item
				md={2}
				xs={12}
				name={`Drill ${drill.order}`}
				drill={drill}
				handleClick={handleClick}
			/>
			<GridItem
				item
				md={3}
				xs={12}
				name={drill.name}
				drill={drill}
				handleClick={handleClick}
			/>
			<GridItem
				item
				md={2}
				xs={12}
				name={drill.published ? 'Yes' : 'No'}
				drill={drill}
				handleClick={handleClick}
			/>
			<GridItem
				item
				md={2}
				xs={12}
				name={drill.created_at}
				drill={drill}
				handleClick={handleClick}
			/>

			<Grid className={classes.item} item md={1} xs={12}>
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 80,
		paddingLeft: 20,
		paddingRight: 20
	},
	item: { verticalAlign: 'center', '&:hover': { backgroundColor: '#fff' } },
	text: { paddingTop: 20 },
	icon: { color: theme.palette.common.grey, marginLeft: 20, marginTop: 20 },
	button: { paddingTop: 20, paddingBottom: 20 }
}));

export default TrainingPlanDrillItem;
