import { isEmpty } from 'validations';

export const trainingPlanValidation = (trainingPlan) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(trainingPlan.name)) {
		isValid = false;
		messages.push('Name is required');
	}

	if (isEmpty(trainingPlan.description)) {
		isValid = false;
		messages.push('Description is required');
	}

	if (isEmpty(trainingPlan.category_ids)) {
		isValid = false;
		messages.push('Skill Categories are required');
	}

	if (isEmpty(trainingPlan.order)) {
		isValid = false;
		messages.push('Order is required');
	}

	return {
		isValid,
		messages
	};
};
