import React from 'react';

import { useResource } from 'hooks';

const useTrainingPlans = ({ id }) => {
	const {
		id: trainingPlanId,
		isLoading,
		resource: trainingPlan,
		resources: trainingPlans,
		errors: trainingPlanErrors,
		findOne: findTrainingPlan,
		findMany: findTrainingPlans,
		create: createTrainingPlan,
		update: updateTrainingPlan,
		destroy: destroyTrainingPlan,
		isEmpty: isEmptyTrainingPlan,
		...rest
	} = useResource({
		id: id,
		url: '/api/v1/admin/training_plans',
		name: 'training_plan',
		label: 'Training Plan'
	});

	return {
		trainingPlanId,
		trainingPlan,
		trainingPlans,
		trainingPlanErrors,
		isLoading,
		findTrainingPlan,
		findTrainingPlans,
		createTrainingPlan,
		updateTrainingPlan,
		destroyTrainingPlan,
		isEmptyTrainingPlan,
		...rest
	};
};

export default useTrainingPlans;
