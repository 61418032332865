import React, { useEffect } from 'react';

import {
	Box,
	Button,
	Container,
	List,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@material-ui/core';

import { useAlerts, useNavigation, useSelected, useTrainingPlans } from 'hooks';
import TrainingPlanSortableList from 'features/trainingPlan/trainingPlan/TrainingPlanSortableList';
import TrainingPlanItem from 'features/trainingPlan/trainingPlan/TrainingPlanItem';
import { SortableTableHeader } from 'components';
import { Pagination } from '@material-ui/lab';

const TABLE_HEADERS = [
	{
		value: 'name',
		label: 'Name',
		sortable: true,
		hidden: false
	},

	{
		value: 'description',
		label: 'Description',
		sortable: true,
		hidden: false
	},

	{
		value: 'published',
		label: 'Published',
		sortable: true,
		hidden: false
	},

	{
		value: 'created_at',
		label: 'Created On',
		sortable: true,
		hidden: false
	}
];

const TrainingPlanList = ({ handleAddClick }) => {
	const {
		trainingPlans,
		findTrainingPlans,
		destroyTrainingPlan,
		isEmptyTrainingPlan,
		handleSort,
		sortKey,
		sortDirection,
		query,
		page,
		numPages,
		paginate
	} = useTrainingPlans({
		id: null
	});
	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess } = useAlerts();

	const { handleClick, handleEditClick } = useNavigation({
		history,
		url: '/training-plans'
	});

	const handleDeleteClick = async (plan) => {
		await destroyTrainingPlan(plan);
		showAlertSuccess('Training plan has been deleted');
		findTrainingPlans();
	};

	const onDragEnd = (result) => {};

	useEffect(() => {
		findTrainingPlans(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			{isEmptyTrainingPlan ? (
				<Container maxWidth="lg">
					<Box
						p={2}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						height="100%"
						width="100%"
						sx={{ minHeight: 600 }}
					>
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							{'No Training Plans'}
						</Typography>
						<Button
							onClick={handleAddClick}
							color="primary"
							variant="contained"
						>
							Add a training plan
						</Button>
					</Box>
				</Container>
			) : (
				<TableContainer component={Paper} elevation={0}>
					<Table>
						<TableHead>
							<TableRow>
								{TABLE_HEADERS.filter((h) => !h.hidden).map((header, idx) => (
									<SortableTableHeader
										key={idx}
										handleSort={handleSort}
										value={`topics.${header.value}`}
										sortable={header.sortable}
										sortKey={sortKey}
										sortDirection={sortDirection}
									>
										{header.label}
									</SortableTableHeader>
								))}
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{trainingPlans.map((item) => (
								<TrainingPlanItem
									key={item.id}
									plan={item}
									isSelectable={false}
									handleClick={handleClick}
									handleEditClick={handleEditClick}
									handleDeleteClick={handleDeleteClick}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}

			{!isEmptyTrainingPlan && (
				<Box my={1}>
					<Pagination
						onChange={(ev, page) => paginate(page)}
						color="primary"
						size="small"
						shape="rounded"
						page={page}
						count={numPages}
					/>
				</Box>
			)}
		</>
	);
};

export default TrainingPlanList;
