import React, { useEffect } from 'react';

import { Box, Button, Container } from '@material-ui/core';
import { Check, Train } from '@material-ui/icons';

import { SubheaderV2 } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useAlerts,
	useLessons,
	useQuery,
	useQueryParams,
	useUploadFileInMemory
} from 'hooks';
import TrainingPlanLessonForm from 'features/trainingPlan/TrainingPlanLessonForm';
import { validateLesson } from 'validations/lessons';

const TrainingPlanLessonEdit = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const query = useQueryParams();

	const { memoryFile } = useUploadFileInMemory();
	const { showAlertSuccess, showAlertError } = useAlerts();
	const {
		lesson,
		handleChange,
		updateLesson,
		findLesson,
		deleteFile,
		uploadFile
	} = useLessons({
		id: id
	});

	const training_plan_drill_id = query.get('training_plan_drill_id');
	const training_plan_id = query.get('training_plan_id');

	const handleSaveClick = async () => {
		const valid = validateLesson(lesson);

		if (valid.isValid) {
			await updateLesson(lesson);
			showAlertSuccess('Lesson has been updated');

			if (training_plan_drill_id) {
				return navigate(`/training-plan-drills/${training_plan_drill_id}`);
			}

			if (training_plan_id) {
				return navigate(`/training-plans/${training_plan_id}`);
			}
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findLesson(id);
	};
	const handleDelete = async (type) => {
		await deleteFile(type);
		findLesson(id);
	};

	useEffect(() => {
		if (id) findLesson(id);
	}, []);

	return (
		<div>
			<SubheaderV2
				title="Update Training Plan Lesson"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save Lesson
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<TrainingPlanLessonForm
						lesson={lesson}
						trainingPlanTitle="demo"
						memoryFile={memoryFile}
						handleUpload={handleUpload}
						handleDelete={handleDelete}
						handleChange={handleChange}
					/>
				</Box>
			</Container>
		</div>
	);
};

export default TrainingPlanLessonEdit;
