import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Paper } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { useTrainingPlanDrills } from 'hooks';
import { Subheader, TabPanel } from 'components';
import TrainingPlanDetails from 'features/trainingPlan/TrainingPlanDetails';
import TrainingPlanLessonDetails from 'features/trainingPlan/TrainingPlanLessonDetails';
import { configure } from 'test-utils';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'training-plan-drills',
		title: 'Training Plan Drill'
	},
	{
		url: 'lessons',
		title: 'Lesson'
	},
	{
		url: 'submissions',
		title: 'Submissions'
	}
];

const TrainingPlanDrillShow = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [lesson, setLesson] = useState([]);
	const [tabValue, setTabValue] = useState(0);
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);

	const { trainingPlanDrill, findTrainingPlanDrill, isLoading } =
		useTrainingPlanDrills({ id: id });

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const handleEdit = () => {
		if (createConfig.url === 'lessons' && lesson?.id) {
			navigate(
				`/training-plans/lesson/${lesson.id}/edit?training_plan_drill_id=${trainingPlanDrill.id}`
			);
		} else {
			navigate(`/training-plan-drills/${trainingPlanDrill.id}/edit`);
		}
	};

	const createButton = useMemo(() => {
		return createConfig.url !== 'submissions' ? (
			<Button
				variant="contained"
				startIcon={'Edit'}
				onClick={handleEdit}
				color="primary"
			>
				{createConfig.title}
			</Button>
		) : null;
	}, [createConfig.url]);

	useEffect(() => {
		if (id) {
			findTrainingPlanDrill(id);
		}
	}, [id]);

	useEffect(() => {
		if (trainingPlanDrill?.lesson) {
			setLesson(trainingPlanDrill.lesson);
		}
	}, [trainingPlanDrill]);

	return (
		<div>
			<Subheader
				title={isLoading ? 'Loading...' : trainingPlanDrill.name}
				buttons={[createButton]}
			/>
			<Container maxWidth="lg">
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="Training Plan"
				>
					<Tab label="Training Plan Drill" />
					<Tab label="Lesson" />
					<Tab label="Submissions" />
				</Tabs>
				<Paper>
					<TabPanel value={tabValue} index={0}>
						{trainingPlanDrill && (
							<TrainingPlanDetails trainingPlan={trainingPlanDrill} />
						)}
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<TrainingPlanLessonDetails lesson={lesson} />
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						<div>submissions</div>
					</TabPanel>
				</Paper>
			</Container>
		</div>
	);
};

export default TrainingPlanDrillShow;
