import React, { useMemo, useState } from 'react';

import {
	AppBar,
	Button,
	Container,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core';
import { Add, Info } from '@material-ui/icons';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { Subheader, TabPanel } from 'components';
import TrainingPlanDrillList from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillList';
import TrainingPlanList from 'features/trainingPlan/trainingPlan/TrainingPlanList';
import { useNavigation } from 'hooks';
import { useNavigate } from 'react-router-dom';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'training-plan-drills',
		title: 'Training Plan Drill'
	},
	{
		url: 'training-plans',
		title: 'Training Plan'
	}
];

const TrainingPlans = () => {
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);
	const [tabValue, setTabValue] = useState(0);
	const navigate = useNavigate();
	const classes = useStyles();

	const handleAddNew = () => {
		if (createConfig.url === 'training-plan-drills') {
			navigate('/training-plan-drills/new');
		} else {
			navigate('/training-plans/new');
		}
	};

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const createButton = useMemo(() => {
		return (
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={handleAddNew}
				color="primary"
			>
				{createConfig.title}
			</Button>
		);
	}, [createConfig.url]);

	return (
		<>
			<Subheader title="Training Plans" buttons={[createButton]} />
			<Container maxWidth="lg">
				<div className={classes.infoContainer}>
					<Info color="primary" />
					<div className={classes.infoText}>{'This is experimental'}</div>
				</div>
				<>
					<AppBar
						position="static"
						style={{ background: '#FFF', color: '#000' }}
					></AppBar>

					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						aria-label="Training Plan"
					>
						<Tab label="Training Plan Drills" />
						<Tab label="Training Plans" />
					</Tabs>
					<Paper>
						<TabPanel value={tabValue} index={0}>
							<TrainingPlanDrillList
								title={'Drills'}
								handleAddClick={handleAddNew}
							/>
						</TabPanel>
						<TabPanel value={tabValue} index={1}>
							<TrainingPlanList
								title={'Training Plans'}
								handleAddClick={handleAddNew}
							/>
						</TabPanel>
					</Paper>
				</>
			</Container>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	infoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		height: 50,
		backgroundColor: '#fff8c4',
		borderRadius: 5,
		gap: 10
	},
	infoText: {
		alignItems: 'center',
		color: theme.palette.text.secondary
	}
}));

export default TrainingPlans;
