import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthRoute } from 'components';

//auth
import ForgotPassword from 'features/auth/ForgotPassword';
import Login from 'features/auth/Login';
import ResetPassword from 'features/auth/ResetPassword';
import VerifyPin from 'features/auth/VerifyPin';

//routes

//benefits
import ArticleEdit from 'features/articles/ArticleEdit';
import ArticleList from 'features/articles/ArticleList';
import ArticleNew from 'features/articles/ArticleNew';
import ArticleShow from 'features/articles/ArticleShow';

//users
import UserEdit from 'features/users/UserEdit';
import UserList from 'features/users/UserList';
import UserNew from 'features/users/UserNew';
import UserShow from 'features/users/UserShow';

//benefits
import BenefitEdit from 'features/benefits/BenefitEdit';
import BenefitList from 'features/benefits/BenefitList';
import BenefitNew from 'features/benefits/BenefitNew';
import BenefitShow from 'features/benefits/BenefitShow';

//courses
import CourseEdit from 'features/courses/CourseEdit';
import CourseList from 'features/courses/CourseList';
import CourseNew from 'features/courses/CourseNew';
import CourseShow from 'features/courses/CourseShow';

//clubs
import ClubEdit from 'features/clubs/ClubEdit';
import ClubList from 'features/clubs/ClubList';
import ClubNew from 'features/clubs/ClubNew';
import ClubShow from 'features/clubs/ClubShow';

//lessons
import LessonEdit from 'features/courses/lessons/LessonEdit';
import LessonNew from 'features/courses/lessons/LessonNew';
import LessonShow from 'features/courses/lessons/LessonShow';

//trainers
import TrainerEdit from 'features/trainers/TrainerEdit';
import TrainerList from 'features/trainers/TrainerList';
import TrainerNew from 'features/trainers/TrainerNew';
import TrainerShow from 'features/trainers/TrainerShow';

//Event-Partners
import EventPartners from 'features/events-partner/index';
import EventUserDetail from 'features/events/user/UserDetail';

//topics
import TopicEdit from 'features/topics/TopicEdit';
import TopicList from 'features/topics/TopicList';
import TopicNew from 'features/topics/TopicNew';
import TopicShow from 'features/topics/TopicShow';

//editorials
import EditorialEdit from 'features/editorials/EditorialEdit';
import EditorialList from 'features/editorials/EditorialList';
import EditorialNew from 'features/editorials/EditorialNew';
import EditorialShow from 'features/editorials/EditorialShow';

//events
import EventEdit from 'features/events/EventEdit';
import EventList from 'features/events/EventList';
import EventNew from 'features/events/EventNew';
import EventShow from 'features/events/EventShow';

//events
import VideoEdit from 'features/videos/VideoEdit';
import VideoList from 'features/videos/VideoList';
import VideoNew from 'features/videos/VideoNew';
import VideoShow from 'features/videos/VideoShow';

//feedback
import FeedbackList from 'features/feedback/FeedbackList';
import FeedbackAttemptEdit from 'features/feedback/attempt/FeedbackAttemptEdit';
import FeedbackAttemptShow from 'features/feedback/attempt/FeedbackAttemptShow';
import FeedbackDrillEdit from 'features/feedback/drill/FeedbackDrillEdit';
import FeedbackDrillNew from 'features/feedback/drill/FeedbackDrillNew';
import FeedbackDrillShow from 'features/feedback/drill/FeedbackDrillShow';

//rewards
import Reward from 'features/rewards/Reward';
import RewardEdit from 'features/rewards/RewardEdit';
import RewardNew from 'features/rewards/RewardNew';
import RewardShow from 'features/rewards/RewardShow';

//achievements
import AchievementEdit from 'features/achievements/AchievementEdit';
import AchievementNew from 'features/achievements/AchievementNew';
import AchievementShow from 'features/achievements/AchievementShow';

//lotteries
import Lottery from 'features/lotteries/Lottery';
import LotteryEdit from 'features/lotteries/LotteryEdit';
import LotteryNew from 'features/lotteries/LotteryNew';
import LotterySelectWinner from 'features/lotteries/LotterySelectWinner';
import LotteryShow from 'features/lotteries/LotteryShow';

//lottery_draws
import LotteryDrawEdit from 'features/lotteryDraws/LotteryDrawEdit';
import LotteryDrawNew from 'features/lotteryDraws/LotteryDrawNew';
import LotteryDrawShow from 'features/lotteryDraws/LotteryDrawShow';

//referrals
import ReferralList from 'features/referrals/ReferralList';
import ReferralShow from 'features/referrals/ReferralShow';

//promo codes
import PromoCodeEdit from 'features/promoCode/PromoCodeEdit';
import PromoCodeNew from 'features/promoCode/PromoCodeNew';
import PromoCodeShow from 'features/promoCode/PromoCodeShow';

// partners
import PartnerNew from 'features/partners/PartnerNew';
import PartnerShow from 'features/partners/PartnerShow';
import PromoCode from 'features/promoCode/PromoCode';
import PartnerEdit from 'features/partners/PartnerEdit';

// promo rules
import PromoRuleEdit from 'features/promoCode/PromoRules/PromoRuleEdit';
import PromoRuleNew from 'features/promoCode/PromoRules/PromoRuleNew';
import PromoRuleShow from 'features/promoCode/PromoRules/PromoRuleShow';

// settings
import SettingsEdit from 'features/settings/SettingEdit';

// Training Plans
import TrainingPlans from 'features/trainingPlan/TrainingPlans';
import TrainingPlanLessonEdit from 'features/trainingPlan/TrainingPlanLessonEdit';
import TrainingPlanShow from 'features/trainingPlan/trainingPlan/TrainingPlanShow';

// Training Plan Drills
import TrainingPlanDrillShow from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillShow';
import TrainingPlanDrillNew from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillNew';
import TrainingPlanDrillEdit from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillEdit';

const Router = () => (
	<Routes>
		<Route path="/" element={<Login />} exact />
		<Route path="/login/:token" element={<Login />} />
		<Route path="/login" element={<Login />} />
		<Route path="/forgot_password" element={<ForgotPassword />} exact />
		<Route path="/verify_pin" element={<VerifyPin />} exact />
		<Route path="/reset_password" element={<ResetPassword />} exact />
		<Route path="/users/passwords/edit" element={<ResetPassword />} exact />
		<Route element={<AuthRoute />}>
			<Route path="/articles/new" element={<ArticleNew />} exact />
			<Route path="/articles/:id/edit" element={<ArticleEdit />} exact />
			<Route path="/articles" element={<ArticleList />} exact />
			<Route path="/articles/:id" element={<ArticleShow />} exact />

			<Route path="/users/new" element={<UserNew />} exact />
			<Route path="/users/:id/edit" element={<UserEdit />} exact />
			<Route path="/users" element={<UserList />} exact />
			<Route path="/users/:id" element={<UserShow />} exact />

			<Route path="/benefits/new" element={<BenefitNew />} exact />
			<Route path="/benefits/:id/edit" element={<BenefitEdit />} exact />
			<Route path="/benefits" element={<BenefitList />} exact />
			<Route path="/benefits/:id" element={<BenefitShow />} exact />

			<Route path="/clubs/new" element={<ClubNew />} exact />
			<Route path="/clubs/:id/edit" element={<ClubEdit />} exact />
			<Route path="/clubs" element={<ClubList />} exact />
			<Route path="/clubs/:id" element={<ClubShow />} exact />

			<Route path="/courses/new" element={<CourseNew />} exact />
			<Route path="/courses/:id/edit" element={<CourseEdit />} exact />
			<Route path="/courses" element={<CourseList />} exact />
			<Route path="/courses/:id" element={<CourseShow />} exact />

			<Route path="/editorials/new" element={<EditorialNew />} exact />
			<Route path="/editorials/:id/edit" element={<EditorialEdit />} exact />
			<Route path="/editorials" element={<EditorialList />} exact />
			<Route path="/editorials/:id" element={<EditorialShow />} exact />

			<Route path="/lessons/new" element={<LessonNew />} exact />
			<Route path="/lessons/:id/edit" element={<LessonEdit />} exact />
			<Route path="/lessons/:id" element={<LessonShow />} exact />

			<Route path="/trainers/new" element={<TrainerNew />} exact />
			<Route path="/trainers/:id/edit" element={<TrainerEdit />} exact />
			<Route path="/trainers" element={<TrainerList />} exact />
			<Route path="/trainers/:id" element={<TrainerShow />} exact />

			<Route path="/topics/new" element={<TopicNew />} exact />
			<Route path="/topics/:id/edit" element={<TopicEdit />} exact />
			<Route path="/topics" element={<TopicList />} exact />
			<Route path="/topics/:id" element={<TopicShow />} exact />

			<Route path="/events-partners" element={<EventPartners />} exact />

			<Route path="/events/new" element={<EventNew />} exact />
			<Route path="/events/:id/edit" element={<EventEdit />} exact />
			<Route path="/events" element={<EventList />} exact />
			<Route path="/events/:id" element={<EventShow />} exact />
			<Route
				path="/events/:id/entries/:entryId"
				element={<EventUserDetail />}
				exact
			/>
			<Route
				path="/events/new/partner/:partnerId"
				element={<EventNew />}
				exact
			/>

			<Route path="/partners/new" element={<PartnerNew />} exact />
			<Route path="/partners/:id/edit" element={<PartnerEdit />} exact />
			<Route path="/partners/:id" element={<PartnerShow />} exact />

			<Route path="/feedback" element={<FeedbackList />} exact />
			<Route
				path="/feedback_attempt/:id/edit"
				element={<FeedbackAttemptEdit />}
				exact
			/>
			<Route
				path="/feedback_attempt/:id"
				element={<FeedbackAttemptShow />}
				exact
			/>
			<Route path="/feedback_drill/new" element={<FeedbackDrillNew />} exact />
			<Route
				path="/feedback_drill/:id/edit"
				element={<FeedbackDrillEdit />}
				exact
			/>
			<Route path="/feedback_drill/:id" element={<FeedbackDrillShow />} exact />

			<Route path="/rewards/new" element={<RewardNew />} exact />
			<Route path="/rewards/:id/edit" element={<RewardEdit />} exact />
			<Route path="/rewards/:id" element={<RewardShow />} exact />

			<Route path="/achievements/new" element={<AchievementNew />} exact />
			<Route path="/achievements/:id" element={<AchievementShow />} exact />
			<Route
				path="/achievements/:id/edit"
				element={<AchievementEdit />}
				exact
			/>

			<Route path="/rewards" element={<Reward />} exact />

			<Route path="/lotteries/new" element={<LotteryNew />} exact />
			<Route path="/lotteries/:id/edit" element={<LotteryEdit />} exact />
			<Route
				path="/lotteries/:id/select-winner"
				element={<LotterySelectWinner />}
				exact
			/>
			<Route path="/lotteries" element={<Lottery />} exact />
			<Route path="/lotteries/:id" element={<LotteryShow />} exact />

			<Route path="/referrals" element={<ReferralList />} exact />
			<Route path="/referrals/:id" element={<ReferralShow />} exact />

			<Route path="/lottery_draws/new" element={<LotteryDrawNew />} exact />
			<Route
				path="/lottery_draws/:id/edit"
				element={<LotteryDrawEdit />}
				exact
			/>
			<Route
				path="/lottery_draws/:id/edit"
				element={<LotteryDrawEdit />}
				exact
			/>
			<Route path="/lottery_draws/:id" element={<LotteryDrawShow />} exact />

			<Route path="/videos/new" element={<VideoNew />} exact />
			<Route path="/videos/:id/edit" element={<VideoEdit />} exact />
			<Route path="/videos" element={<VideoList />} exact />
			<Route path="/videos/:id" element={<VideoShow />} exact />

			<Route path="/promo_codes" element={<PromoCode />} exact />
			<Route path="/promo_codes/new" element={<PromoCodeNew />} exact />
			<Route path="/promo_codes/:id" element={<PromoCodeShow />} exact />
			<Route path="/promo_codes/:id/edit" element={<PromoCodeEdit />} exact />

			<Route path="/promo_rules/new" element={<PromoRuleNew />} exact />
			<Route path="/promo_rules/:id" element={<PromoRuleShow />} exact />
			<Route path="/promo_rules/:id/edit" element={<PromoRuleEdit />} exact />

			<Route path="/settings" element={<SettingsEdit />} exact />

			<Route path="/training-plans" element={<TrainingPlans />} exact />
			<Route
				path="/training-plans/lesson/:id/edit"
				element={<TrainingPlanLessonEdit />}
				exact
			/>

			<Route
				path="/training-plan-drills/new"
				element={<TrainingPlanDrillNew />}
				exact
			/>
			<Route
				path="/training-plan-drills/:id"
				element={<TrainingPlanDrillShow />}
				exact
			/>
			<Route
				path="/training-plan-drills/:id/edit"
				element={<TrainingPlanDrillEdit />}
				exact
			/>

			<Route path="/training-plans/:id" element={<TrainingPlanShow />} exact />
		</Route>
	</Routes>
);

export default Router;
